.Block-info-tab {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px;
    border-style: solid;
    border-color: #c1d1d5;
    border-radius: 10px;
    font-size: calc(7px);
    padding: 5px;
    margin: 5px;
}

.Block-tx-tab {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px;
    border-style: solid;
    border-color: #c1d1d5;
    border-radius: 10px;
    font-size: calc(7px);
    padding: 5px;
    margin: 5px;
    overflow-y: scroll;
    height: calc(50vh);
}
.Block-list-tab {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px;
    border-style: solid;
    border-color: #c1d1d5;
    border-radius: 10px;
    font-size: calc(7px);
    padding: 5px;
    margin: 5px;
    overflow-y: scroll;
    height: calc(70vh);
}

.Tab-title {
    font-size: calc(30px);
    font-weight: bold;
    margin: 5px;
}

.Main-title {
    font-size: calc(50px);
    font-weight: bold;
    margin: 15px;
}